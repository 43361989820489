.gfg {
    position: relative;
    width: 525px;
    height: 262.5px;
}
.container{
    position: absolute;
    font-family: Helvetica, sans-serif;
    color:black;
    left: 275px;
    top: 12px;
}
.text-container {
    display: flex;
    width: 180px;
    justify-content: space-between;
}
.date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
}
.num-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sub.ex1 {
    margin-top: -200px;
}
.lottoSize{
    width: 350px;
    height: 175px;
}
